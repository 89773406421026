import styled from "@emotion/styled";
import { headingStyle } from "../BlogPostStyle";

export const Heading = styled.h1`
  ${headingStyle}

  font-size: 3rem;
`;

export const Text = styled.p`
  font-family: Karla, sans-serif;
  line-height: 1.2;
`;
