import styled from "@emotion/styled";
import React from "react";
import { Heading, Text } from "../components/atoms/Heading";
import Layout from "../components/Layout";

const Container = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const NotFoundPage = () => (
  <Layout>
    <Container>
      <Heading>Not found</Heading>
      <Text>We couldn't find what you were looking for</Text>
    </Container>
  </Layout>
);

export default NotFoundPage;
